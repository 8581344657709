
// import 'vidstack/icons';

import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';

import 'mapbox-gl/dist/mapbox-gl.css';
import './css/main.css';

import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// import 'vidstack/player';
// import 'vidstack/player/layouts/default';
// import 'vidstack/player/ui';

barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
        {
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    opacity: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        },
        {
            name: 'swipeFromRight-transition',
            from: {
                // define a custom rule based on the trigger class
                custom: ({ trigger }) => {
                  return trigger.classList && trigger.classList.contains('swipeFromRight');
                },
            },
            leave(data) {
                return gsap.to(data.current.container, {
                    x: '-100%'
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    x: '100%',
                    y: 0
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        },
        {
            name: 'swipeFromLeft-transition',
            from: {
                // define a custom rule based on the trigger class
                custom: ({ trigger }) => {
                  return trigger.classList && trigger.classList.contains('swipeFromLeft');
                },
            },
            leave(data) {
                return gsap.to(data.current.container, {
                    x: '100%'
                });
            },
            enter(data) {
            },
            after(data) {
                return gsap.from(data.next.container, {
                    x: '-100%'
                });
            },
            afterOnce(data) {
            },
            once(data) {
            }
        }
    ],
});


function scrollToTop() {
// window.scrollTo({top: 0, behavior: 'smooth'});
window.scrollTo({top: 0});
};




function hamburgerToggle(preset) {
    import(/* webpackChunkName: "hamburgerToggle" */ "./js/components/hamburgerToggle.js").then(module => {
        const toggle = module.default;
        toggle(preset);
    });
}

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});

let menuCloser = document.getElementById('dropdownCloser');
menuCloser.addEventListener("click", () => {hamburgerToggle('c')});



function videos() {
    let vidPlayers = document.querySelectorAll('media-player');
    if (vidPlayers.length > 0) {
        let vd;
        for (vd=0; vd < vidPlayers.length; vd++) {
            vidPlayers[vd].addEventListener('provider-change', (event) => {
                const provider = event.detail;
                if (provider?.type === 'hls') {
                    console.log('HLS config change');
                    provider.config = { startLevel: 5 };
                };
            });
        };
    };
};


function galleryCheck() {
    let galleries = document.getElementsByClassName('gallery');
    if (galleries.length > 0) {
      import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
        const galleryGo = module.default;
        galleryGo();
      });
    };
};

function mapCheck() {
    let maps = document.getElementsByClassName('map');
    if (maps.length > 0) {
        import(/* webpackChunkName: "map" */ "./js/components/map.js").then(module => {
            const mapGo = module.default;
            mapGo();
          });
    }
}

let theLogo = document.getElementById('monLogo');
function logoRemove() {
    ScrollTrigger.create({
        trigger: '#footer',
        start: 'top bottom',
        end: 'bottom 90%',
        // onEnter: (self) => thebub.classList.remove('-translate-y-[120%]'),
        // onLeave: (self) => thebub.classList.add('-translate-y-[120%]'),
        onEnter: (self) => theLogo.classList.add('hidden'),
        onLeave: (self) => theLogo.classList.remove('hidden'),
        onLeaveBack: (self) => theLogo.classList.remove('hidden'),
        // markers: {startColor: "black", endColor: "black", fontSize: "18px", fontWeight: "bold", indent: 20}
    })
}

function killScroll() {
    let Alltrigger = ScrollTrigger.getAll()
    for (let i = 0; i < Alltrigger.length; i++) {
    Alltrigger[i].kill(true)
    }
}


function menuCheck(data) {
    let tickBut = document.getElementById('ticketsButton');
    let homeBut = document.getElementById('homeButton')
    if (tickBut != undefined && tickBut != null) {
            if (data.next.namespace == 'tickets' || data.next.namespace == 'artists' ) {
                tickBut.classList.add('opacity-0');
                
                setTimeout(() => {
                    tickBut.classList.add('hidden');
                    homeBut.classList.remove('hidden');
                }, 600)
                setTimeout(() => {
                    homeBut.classList.remove('opacity-0');
                }, 800)
            } else {
                tickBut.classList.remove('hidden');
                homeBut.classList.add('opacity-0');
                setTimeout(() => {
                    homeBut.classList.add('hidden')
                }, 600)
                setTimeout(() => {
                    tickBut.classList.remove('opacity-0');
                }, 800)
            }
    } else {

        if (data.next.namespace == 'tickets' || data.next.namespace == 'artists' ) {
            // tickBut.classList.add('opacity-0');
            
            setTimeout(() => {
                // tickBut.classList.add('hidden');
                homeBut.classList.remove('hidden');
            }, 600)
            setTimeout(() => {
                homeBut.classList.remove('opacity-0');
            }, 800)
        } else {
            // tickBut.classList.remove('hidden');
            homeBut.classList.add('opacity-0');
            setTimeout(() => {
                homeBut.classList.add('hidden')
            }, 600)
            setTimeout(() => {
                // tickBut.classList.remove('opacity-0');
            }, 800)
        }

    };
}


function autoRefresh() {
    let refreshIt = document.getElementsByClassName('autoRefresh');
    if (refreshIt.length > 0) {
        import(/* webpackChunkName: "autoRefresh" */ "./js/components/autoRefresh.js").then(module => {
            const refreshGo = module.default;
            refreshGo();
          });
    }
}

function weez() {
    let weezIt = document.getElementsByClassName('weez');
    if (weezIt.length > 0) {
        import(/* webpackChunkName: "weez" */ "./js/components/weez.js").then(module => {
            const weezGo = module.default;
            weezGo();
          });
    }
}






barba.hooks.once((data) => {
    videos();
    galleryCheck();
    mapCheck();
    logoRemove();
    menuCheck(data);
    autoRefresh();
    
});

barba.hooks.afterOnce((data) => {
    weez();
});

barba.hooks.before((data) => {
    hamburgerToggle('c');
})

barba.hooks.beforeEnter((data) => {
    scrollToTop();
    videos();
    galleryCheck();
    mapCheck();
    menuCheck(data);
});


barba.hooks.after((data) => {
    theLogo.classList.remove('hidden'),
    killScroll();
    logoRemove();
    autoRefresh();
    weez();
});
